<template>
  <div>
    <Necker></Necker>
    <div class="container py-16 leading-loose">
      <h1 class="text-4xl text-primary font-bold mb-6">
        學習研究社 隱私權保護政策
      </h1>
      <p>
        學習研究社
        為尊重並保護您的隱私權，請詳閱這份隱私權保護政策，以協助您瞭解
        學習研究社 如何蒐集、應用以及保護您所提供的個人資訊。
      </p>
      <p class="text-primary text-2xl mt-8 mb-2">適用範圍</p>
      <p>
        本隱私權保護政策，適用於您在 學習研究社
        旗下網站瀏覽時，所涉及的個人資料蒐集、運用與保護。
      </p>
      <p class="text-primary text-2xl mt-8 mb-2">個人資料之蒐集與使用方式</p>
      <p>
        當您在瀏覽 學習研究社
        網站時，我們蒐集您的個人資料，並將這些資料與學習研究社所擁有您的個人資料相結合。我們並會接收、紀錄並保留伺服器所產生的相關記錄，包括您使用連線設備的IP位址、連線時間、瀏覽器種類及點選紀錄等。
        學習研究社會將資料做以下用途：提供並滿足您對學習研究社服務的要求、改進我們的服務、進行調查或研究、作成統計資料、製作並提供匿名報告、或為其他合法之使用。
        當 學習研究社
        要蒐集您的個人資料時，會依不同的活動請您填寫個人資料（例如姓名、生日、電子郵件、電話號碼、住址等），目的在於確認您的身分，以便於提供精確的服務、完成交易或進行統計分析。
        學習研究社 不會在您不知情的情況下，取得您的任何個人資料。
        請注意，在您透過網站討論區、聊天室等公開範圍所主動發表的個人資料，均可能被其他第三人蒐集或使用，這些資料並不適用這份隱私權保護聲明，請您注意公開發言時的內容。
      </p>
      <p class="text-4xl text-primary font-bold mt-24 mb-6">
        學習研究社 與第三者共用個人資料
      </p>
      <p>
        學習研究社 不會向任何人出售、交換或展示您的個人資料。
        但有下列情況之一時，學習研究社
        有可能會向其他人士或公司提供你的個人資料：
        ．在與其他人士或機構共用資料前有取得您的授權
        ．因應行政、司法單位之要求、行使或維護學習研究社的法律權利或對訴訟上之請求為防禦
        ．當您在本站的行為違反 學習研究社
        服務條款或妨礙第三者權益，或為了調查或防止違法活動、或為了防止或排除人身安全之威脅，或其他法律有特別規定之情形，而有必要分享您的個人資料
        ．必須有您部份的個人資料才能使用或順利取得、享有 學習研究社
        所提供的特定或與第三者合作的服務
      </p>
      <p class="text-primary text-2xl mt-8 mb-2">協力夥伴</p>
      <p>
        學習研究社
        為了提供您其他服務或優惠權益，需要與第三者共用您的個人資料時，學習研究社
        將在活動網頁中充分說明使用範圍、使用目的、使用方式、使用期間，您可以自由選擇是否接受這項特定服務或活動。
        學習研究社
        將以合約約束協力夥伴，協力夥伴必須履約保護您的個人資料，不能與他人分享，或用於行銷和其他任何目的。
      </p>
      <p class="text-primary text-2xl mt-8 mb-2">Cookies的運用</p>
      <p>
        學習研究社會在您的電腦上設定並存取學習研究社 cookie。 Cookies
        是伺服器經由瀏覽器，在網友的硬碟中儲存一些簡短資訊。 透過 Cookies
        的功能，學習研究社 可以做到： ．提供您不同的個人化服務
        ．統計瀏覽人次及流量 ．記錄您使用本網站時的喜好或習慣
        大多數的瀏覽器可以自行設定是否啟動 Cookies 或更改 Cookies的接受程度。
        如果您拒絕所有的 Cookies，將造成無法使用 學習研究社 大部分的功能。
      </p>
      <p class="text-primary text-2xl mt-8 mb-2">個人資料修改之政策</p>
      <p>
        您可以隨時登入 學習研究社
        並修改您所輸入的個人資料（如姓名、暱稱、生日、住址、電話、電子信箱等）及訊息通知的方式，包括透過手機寄發之優惠資訊及EDM大小報、工商報等，或是其他會員與您互動的訊息，學習研究社
        皆賦予您決定和修改之權力。您也可以請求製給複製本、請求學習研究社停止蒐集處理利用或請求刪除您的個人資料。
      </p>
      <p class="text-primary text-2xl mt-8 mb-2">自我保護措施</p>
      <p>
        會員須妥善保存自己的帳號及密碼，不得將密碼告訴第三者，甚或提供第三者使用。當您要離開電腦前或是不再使用網站服務時，務必先登出管理帳號，若您是與他人共用電腦或使用公共電腦，切記要關閉瀏覽器視窗，以防止他人讀取您的個人資料及安全。
        學習研究社
        登入會員網站管理，需要透過登記Email帳號及密碼才能進行，密碼建議設定4~10位的密碼，以英文字母、數字混合為佳。
      </p>
      <p class="text-primary text-2xl mt-8 mb-2">看法與建議</p>
      <p>
        如果您有任何看法與建議，請寫信填寫下方&nbsp;客服信箱&nbsp;表格告訴我們。
      </p>
    </div>
  </div>
</template>

<script setup>
import Necker from '@/views/_necker/Index.vue';
import { provide, ref } from 'vue';

provide('page', {
  breadcrumbs: ref(['隱私權保護政策']),
  title: ref('隱私權保護政策'),
  pcBg: ref('banner04.png'),
  mobileBg: ref('banner04_m.png'),
});
</script>

<style lang="sass" scoped></style>
